import axios from "axios";
import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
const Userstory = () => {
	const [hostUrl, setHostUrl] = useState("");
	const [email, setEmail] = useState("");
	const [token, setToken] = useState("");
	const [projects, setProjects] = useState<any>([]);
	const [selectedProject, setSelectedProject] = useState<string | null>(null);
	const [stories, setStories] = useState<string[]>([]);
	const [selectedStory, setSelectedStory] = useState<string | null>(null);
	const [testcase, setTestcase] = useState<string | null>(null);
	const [testcases, setTestcases] = useState<any>(null);
	const parseTestCases = (text: any) => {
		const cases = text.split("---").filter(Boolean); // Split on "---" to separate test cases
		return cases.map((testCaseText: any, index: any) => {
			const idMatch = testCaseText.match(/Test Case ID:\s*(TC\d+)/);
			const summaryMatch = testCaseText.match(/Summary:\s*(.+)/);
			const preconditionsMatch = testCaseText.match(/Pre-conditions:\s*(.+)/);
			const stepsMatch = testCaseText.match(
				/Steps:\s*\n([\s\S]*?)\nExpected Results:/,
			);
			const expectedResultMatch = testCaseText.match(
				/Expected Results:\s*(.+)/,
			);
			const actualResultMatch = testCaseText.match(/Actual Results:\s*(.+)/);
			const passFailMatch = testCaseText.match(/Pass\/Fail Status:\s*(.+)/);

			return {
				id: idMatch ? idMatch[1] : `Test Case ${index + 1}`,
				summary: summaryMatch ? summaryMatch[1] : "",
				preconditions: preconditionsMatch ? preconditionsMatch[1] : "",
				steps: stepsMatch
					? stepsMatch[1]
							.trim()
							.split(/\d+\.\s/)
							.filter(Boolean)
					: [],
				expectedResult: expectedResultMatch ? expectedResultMatch[1] : "",
				actualResult: actualResultMatch ? actualResultMatch[1] : "",
				passFailStatus: passFailMatch ? passFailMatch[1] : "",
			};
		});
	};
	// Fetch projects when credentials are submitted
	const fetchProjects = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/jirapoc/projects`,
				{
					params: {
						hostUrl,
						email,
						token,
					},
				},
			);
			setProjects(response.data);
		} catch (error) {
			console.error("Error fetching projects:", error);
		}
	};

	// Fetch stories for selected project
	const fetchStories = async (id: any) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/jirapoc/stories?epicId=${id}`,
				{
					params: {
						hostUrl,
						email,
						token,
					},
				},
			);
			console.log(response.data);

			setStories(response.data);
		} catch (error) {
			console.error("Error fetching stories:", error);
		}
	};

	// Fetch test case for the selected story
	const fetchTestcase = async () => {
		try {
			// Replace this with actual API endpoint for test cases
			const response: any = await axios.post(
				`${process.env.REACT_APP_API_URL}/jirapoc/testcase?storyId=${selectedStory}&hostUrl=${hostUrl}&email=${email}&token=${token}`,
			);
			console.log(response?.data?.testCase);

			const testcases: any = parseTestCases(response?.data?.testCase);
			setTestcases(testcases);
		} catch (error) {
			console.error("Error fetching testcase:", error);
		}
	};

	const parseTestCasesForExport = () => {
		return testcases.map((tc: any) => ({
			"Test Case ID": tc.id,
			Summary: tc.summary,
			"Pre-conditions": tc.preconditions,
			Steps: tc.steps.join("\n"), // Join steps into a single string with line breaks
			"Expected Results": tc.expectedResult,
			"Actual Results": tc.actualResult,
			"Pass/Fail Status": tc.passFailStatus,
		}));
	};

	const exportToExcel = () => {
		const data = parseTestCasesForExport();
		const worksheet = XLSX.utils.json_to_sheet(data);

		// Adjust column widths
		worksheet["!cols"] = [
			{ wch: 15 }, // Test Case ID
			{ wch: 30 }, // Summary
			{ wch: 40 }, // Pre-conditions
			{ wch: 40 }, // Steps
			{ wch: 40 }, // Expected Results
			{ wch: 25 }, // Actual Results
			{ wch: 15 }, // Pass/Fail Status
		];

		// Enable wrapping for specified columns
		const wrapTextCols = ["C", "D", "E"]; // Columns for Pre-conditions, Steps, Expected Results
		wrapTextCols.forEach((col) => {
			for (let i = 2; i <= data.length + 1; i++) {
				const cell = worksheet[`${col}${i}`];
				if (cell) {
					cell.s = {
						alignment: {
							wrapText: true,
							vertical: "top",
						},
					};
				}
			}
		});

		// Define header styles
		const headers = ["A1", "B1", "C1", "D1", "E1", "F1", "G1"];
		headers.forEach((header) => {
			if (worksheet[header]) {
				worksheet[header].s = {
					font: {
						bold: true,
					},
					alignment: {
						horizontal: "center",
					},
				};
			}
		});

		// Set row heights for readability
		worksheet["!rows"] = data.map(() => ({ hpx: 40 }));

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Test Cases");
		XLSX.writeFile(workbook, "Test_Cases.xlsx");
	};

	return (
		<div
			style={{
				maxWidth: "600px",
				margin: "20px auto",
				padding: "20px",
				fontFamily: "Arial, sans-serif",
			}}
		>
			<h2
				style={{
					textAlign: "center",
					color: "#333",
				}}
			>
				Jira Test Case Generator
			</h2>

			{/* Credentials Form */}
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "10px",
					marginBottom: "20px",
				}}
			>
				<input
					type="text"
					placeholder="Jira Host URL"
					value={hostUrl}
					onChange={(e) => setHostUrl(e.target.value)}
					style={{
						padding: "10px",
						borderRadius: "5px",
						border: "1px solid #ccc",
					}}
				/>
				<input
					type="email"
					placeholder="User Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					style={{
						padding: "10px",
						borderRadius: "5px",
						border: "1px solid #ccc",
					}}
				/>
				<input
					type="password"
					placeholder="API Token"
					value={token}
					onChange={(e) => setToken(e.target.value)}
					style={{
						padding: "10px",
						borderRadius: "5px",
						border: "1px solid #ccc",
					}}
				/>
				<button
					onClick={fetchProjects}
					style={{
						padding: "10px",
						backgroundColor: "#007bff",
						color: "#fff",
						border: "none",
						borderRadius: "5px",
						cursor: "pointer",
					}}
				>
					Submit
				</button>
			</div>

			{/* Project Dropdown */}
			{projects.length > 0 && (
				<div
					style={{
						marginBottom: "20px",
					}}
				>
					<label
						style={{
							fontWeight: "bold",
							marginBottom: "5px",
							display: "block",
						}}
					>
						Select Project:
					</label>
					<select
						value={selectedProject || ""}
						onChange={(e) => {
							console.log(e.target);
							setSelectedProject(e.target.value);

							setStories([]); // Reset stories when project changes
							setSelectedStory(null); // Reset selected story
							fetchStories(e.target.value); // Fetch stories for the selected project
						}}
						style={{
							padding: "10px",
							borderRadius: "5px",
							width: "100%",
							border: "1px solid #ccc",
						}}
					>
						<option value="">-- Select a Project --</option>
						{projects.map((project: any) => (
							<option key={project?.name} value={project?.id}>
								{project?.name}
							</option>
						))}
					</select>
				</div>
			)}

			{/* Story Dropdown */}
			{stories.length > 0 && (
				<div
					style={{
						marginBottom: "20px",
					}}
				>
					<label
						style={{
							fontWeight: "bold",
							marginBottom: "5px",
							display: "block",
						}}
					>
						Select Story:
					</label>
					<select
						value={selectedStory || ""}
						onChange={(e) => setSelectedStory(e.target.value)}
						style={{
							padding: "10px",
							borderRadius: "5px",
							width: "100%",
							border: "1px solid #ccc",
						}}
					>
						<option value="">-- Select a Story --</option>
						{stories.map((story: any, index) => (
							<option key={index} value={story?.id}>
								{story?.fields?.summary}
							</option>
						))}
					</select>
				</div>
			)}

			{/* Generate Testcase Button */}
			{selectedStory && (
				<button
					onClick={fetchTestcase}
					style={{
						marginTop: "20px",
						padding: "10px 20px",
						backgroundColor: "#28a745",
						color: "#fff",
						border: "none",
						borderRadius: "5px",
						cursor: "pointer",
						width: "100%",
					}}
				>
					Generate Testcase
				</button>
			)}

			{/* Testcase Display */}
			{testcases && testcases.length > 0 && (
				<div>
					<button
						onClick={exportToExcel}
						style={{
							margin: "10px",
							padding: "10px",
							backgroundColor: "#28a745",
							color: "#fff",
							border: "none",
							borderRadius: "5px",
						}}
					>
						Export to Excel
					</button>
					<div
						style={{
							marginTop: "20px",
						}}
					>
						{testcases.map((testCase: any, index: any) => (
							<div
								key={index}
								style={{
									marginBottom: "20px",
									padding: "15px",
									backgroundColor: "#f8f9fa",
									borderRadius: "5px",
									border: "1px solid #ccc",
								}}
							>
								<h3
									style={{
										color: "#007bff",
									}}
								>
									{testCase.id}
								</h3>
								<p>
									<strong>Summary:</strong> {testCase.summary}
								</p>
								<p>
									<strong>Preconditions:</strong> {testCase.preconditions}
								</p>
								<p>
									<strong>Steps:</strong>
								</p>
								<ul
									style={{
										paddingLeft: "20px",
									}}
								>
									{testCase.steps.map((step: any, stepIndex: any) => (
										<li key={stepIndex}>{step}</li>
									))}
								</ul>
								<p>
									<strong>Expected Result:</strong> {testCase.expectedResult}
								</p>
								<p>
									<strong>Actual Result:</strong>{" "}
									{testCase.actualResult || "(Filled after execution)"}
								</p>
								<p>
									<strong>Pass/Fail Status:</strong>{" "}
									{testCase.passFailStatus || "(Filled after execution)"}
								</p>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default Userstory;
