import React, { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";

interface Page {
	name: string;
}

interface Node {
	name: string;
}

const FigmaToTestcase = () => {
	const [figmaFileKey, setFigmaFileKey] = useState<string>("");
	const [figmaToken, setFigmaToken] = useState<string>("");
	const [pages, setPages] = useState<Page[]>([]);
	const [selectedPage, setSelectedPage] = useState<string>("");
	const [nodes, setNodes] = useState<Node[]>([]);
	const [testCases, setTestCases] = useState<string>("");
	const parseTestCases = () => {
		const caseSections = testCases.split(/\n\n---\n\n/); // Split by each test case block
		const cases = caseSections.map((section) => {
			const testCaseIdMatch = section.match(/\*\*Test Case ID:\*\* (.+)/);
			const summaryMatch = section.match(/\*\*Summary:\*\* (.+)/);
			const preConditionsMatch = section.match(
				/\*\*Pre-conditions:\*\*([\s\S]*?)\*\*Steps:\*\*/,
			);
			const stepsMatch = section.match(
				/\*\*Steps:\*\*([\s\S]*?)\*\*Expected Results:\*\*/,
			);
			const expectedResultsMatch = section.match(
				/\*\*Expected Results:\*\*([\s\S]*?)\*\*Actual Results:\*\*/,
			);
			const actualResultsMatch = section.match(/\*\*Actual Results:\*\* (.+)/);
			const passFailStatusMatch = section.match(
				/\*\*Pass\/Fail Status:\*\* (.+)/,
			);

			return {
				testCaseId: testCaseIdMatch ? testCaseIdMatch[1].trim() : "",
				summary: summaryMatch ? summaryMatch[1].trim() : "",
				preConditions: preConditionsMatch
					? preConditionsMatch[1].trim().replace(/\n/g, " ")
					: "",
				steps: stepsMatch ? stepsMatch[1].trim().replace(/\n/g, " ") : "",
				expectedResults: expectedResultsMatch
					? expectedResultsMatch[1].trim().replace(/\n/g, " ")
					: "",
				actualResults: actualResultsMatch
					? actualResultsMatch[1].trim()
					: "(Filled after execution)",
				passFailStatus: passFailStatusMatch
					? passFailStatusMatch[1].trim()
					: "(Filled after execution)",
			};
		});

		return cases;
	};

	const exportToExcel = () => {
		const parsedCases = parseTestCases();
		const data = parsedCases.map((tc) => ({
			"Test Case ID": tc.testCaseId,
			Summary: tc.summary,
			"Pre-conditions": tc.preConditions,
			Steps: tc.steps,
			"Expected Results": tc.expectedResults,
			"Actual Results": tc.actualResults,
			"Pass/Fail Status": tc.passFailStatus,
		}));

		const worksheet = XLSX.utils.json_to_sheet(data);

		// Adjust column widths
		worksheet["!cols"] = [
			{ wch: 15 }, // Test Case ID
			{ wch: 30 }, // Summary
			{ wch: 40 }, // Pre-conditions
			{ wch: 40 }, // Steps
			{ wch: 40 }, // Expected Results
			{ wch: 25 }, // Actual Results
			{ wch: 15 }, // Pass/Fail Status
		];

		// Enable wrapping for each cell in specific columns
		const wrapTextCols = ["C", "D", "E"]; // Columns for Pre-conditions, Steps, Expected Results

		wrapTextCols.forEach((col) => {
			for (let i = 2; i <= data.length + 1; i++) {
				const cell = worksheet[`${col}${i}`];
				if (cell) {
					cell.s = {
						alignment: {
							wrapText: true,
							vertical: "top",
						},
					};
				}
			}
		});

		// Define header styles
		const headers = ["A1", "B1", "C1", "D1", "E1", "F1", "G1"];
		headers.forEach((header) => {
			if (worksheet[header]) {
				worksheet[header].s = {
					font: {
						bold: true,
					},
					alignment: {
						horizontal: "center",
					},
				};
			}
		});

		// Wrap text and add line breaks for lists
		worksheet["!rows"] = data.map(() => ({ hpx: 40 })); // Increase row height for readability

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Test Cases");

		XLSX.writeFile(workbook, "Test_Cases.xlsx");
	};

	const handleFetchPages = async () => {
		try {
			const response = await axios.post<{
				pages: Page[];
			}>(`${process.env.REACT_APP_API_URL}/figma/get-pages`, {
				figmaFileKey,
				figmaToken,
			});
			setPages(response.data.pages);
		} catch (error) {
			console.error("Error fetching pages:", error);
		}
	};

	const handleFetchNodes = async (pageName: string) => {
		setSelectedPage(pageName);
		try {
			const response = await axios.post<{
				nodes: Node[];
			}>(`${process.env.REACT_APP_API_URL}/figma/get-nodes`, {
				figmaFileKey,
				figmaToken,
				pageName,
			});
			setNodes(response.data.nodes);
		} catch (error) {
			console.error("Error fetching nodes:", error);
		}
	};

	const handleGenerateTestCases = async (nodeName: string) => {
		try {
			const response = await axios.post<{
				testCases: string;
			}>(`${process.env.REACT_APP_API_URL}/figma/generate-testcase`, {
				figmaFileKey,
				figmaToken,
				pageName: selectedPage,
				nodeName,
			});
			setTestCases(response.data.testCases);
		} catch (error) {
			console.error("Error generating test cases:", error);
		}
	};

	// Inline styles for the app
	const appStyles = {
		maxWidth: "800px",
		margin: "40px auto",
		padding: "20px",
		backgroundColor: "#fff",
		borderRadius: "10px",
		boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
		fontFamily: "Arial, sans-serif",
		textAlign: "center" as const,
	};

	const labelStyles = {
		display: "block",
		fontSize: "16px",
		color: "#555",
		marginBottom: "8px",
	};

	const inputStyles = {
		width: "calc(100% - 20px)",
		padding: "10px",
		margin: "10px 0",
		borderRadius: "5px",
		border: "1px solid #ccc",
		fontSize: "16px",
	};

	const buttonStyles = {
		padding: "10px 20px",
		margin: "10px 5px",
		backgroundColor: "#4CAF50",
		color: "white",
		border: "none",
		borderRadius: "5px",
		fontSize: "16px",
		cursor: "pointer",
		transition: "background-color 0.3s ease",
	};

	const buttonHoverStyles = {
		backgroundColor: "#45a049",
	};

	const listItemStyles = {
		marginBottom: "10px",
		listStyleType: "none" as const,
	};

	const buttonContainerStyles = {
		display: "flex",
		flexWrap: "wrap" as const,
		gap: "10px",
		justifyContent: "center",
	};

	const preStyles = {
		backgroundColor: "#f8f8f8",
		padding: "20px",
		borderRadius: "5px",
		border: "1px solid #ddd",
		fontSize: "14px",
		color: "#333",
		textAlign: "left" as const,
	};

	return (
		<div style={appStyles}>
			<h1>Figma to Test Case Generator</h1>

			<div>
				<label style={labelStyles}>Figma File Key:</label>
				<input
					type="text"
					value={figmaFileKey}
					onChange={(e) => setFigmaFileKey(e.target.value)}
					style={inputStyles}
				/>

				<label style={labelStyles}>Figma API Token:</label>
				<input
					type="text"
					value={figmaToken}
					onChange={(e) => setFigmaToken(e.target.value)}
					style={inputStyles}
				/>

				<button
					style={buttonStyles}
					onMouseOver={(e) =>
						(e.currentTarget.style.backgroundColor =
							buttonHoverStyles.backgroundColor)
					}
					onMouseOut={(e) =>
						(e.currentTarget.style.backgroundColor =
							buttonStyles.backgroundColor)
					}
					onClick={handleFetchPages}
				>
					Fetch Pages
				</button>
			</div>

			{pages.length > 0 && (
				<div>
					<h2>Select a Page</h2>
					<div style={buttonContainerStyles}>
						{pages.map((page) => (
							<button
								key={page.name}
								style={buttonStyles}
								onMouseOver={(e) =>
									(e.currentTarget.style.backgroundColor =
										buttonHoverStyles.backgroundColor)
								}
								onMouseOut={(e) =>
									(e.currentTarget.style.backgroundColor =
										buttonStyles.backgroundColor)
								}
								onClick={() => handleFetchNodes(page.name)}
							>
								{page.name}
							</button>
						))}
					</div>
				</div>
			)}

			{nodes.length > 0 && (
				<div>
					<h2>Select a Node</h2>
					<div style={buttonContainerStyles}>
						{nodes.map((node) => (
							<button
								key={node.name}
								style={buttonStyles}
								onMouseOver={(e) =>
									(e.currentTarget.style.backgroundColor =
										buttonHoverStyles.backgroundColor)
								}
								onMouseOut={(e) =>
									(e.currentTarget.style.backgroundColor =
										buttonStyles.backgroundColor)
								}
								onClick={() => handleGenerateTestCases(node.name)}
							>
								{node.name}
							</button>
						))}
					</div>
				</div>
			)}

			{testCases && (
				<div>
					<h2>Generated Test Cases</h2>
					<pre
						style={{
							backgroundColor: "#f8f8f8",
							padding: "20px",
							borderRadius: "5px",
							border: "1px solid #ddd",
							fontSize: "14px",
							color: "#333",
							textAlign: "left",
						}}
					>
						{testCases}
					</pre>
					<button
						style={{
							padding: "10px 20px",
							backgroundColor: "#4CAF50",
							color: "white",
							border: "none",
							borderRadius: "5px",
							fontSize: "16px",
							cursor: "pointer",
							marginTop: "10px",
						}}
						onClick={exportToExcel}
					>
						Export to Excel
					</button>
				</div>
			)}
		</div>
	);
};

export default FigmaToTestcase;
