import AWS from "aws-sdk";
import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from "../api/apiSlice";

const REACT_ACCESS_KEY = "AKIAYSKLKOVKY5JSULVK";
const REACT_AWS_SECRET = "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";
const REACT_REGION = "ap-south-1";

const s3bucket = new AWS.S3({
	accessKeyId: REACT_ACCESS_KEY,
	secretAccessKey: REACT_AWS_SECRET,
	region: REACT_REGION,
});

export async function uploadFileToAWS(
	file: any,
	bucketName = "testbuddy-bucket",
) {
	return new Promise((resolve, reject) => {
		const params = {
			Bucket: bucketName,
			Key: file?.filename ? `${file.filename}` : `${file?.name}`,
			Body: file.data, //*
			ACL: "public-read",
			ContentType: "application/json",
		};

		s3bucket.upload(params, (error: any, data: any) => {
			if (error) {
				// Wrap the error information in an Error object
				return reject(new Error(`Upload failed: ${error.message}`));
			}
			return resolve({ success: true, path: data.Location });
		});
	});
}

export function getRowTextColor(type: any) {
	switch (type) {
		case "log":
			return "#868E96";
		case "warn":
			return "#f39c12";
		case "exception":
		case "error":
			return "#e74c3c";
		case "debug":
			return "#868E96";
		case "info":
			return "#868E96";
		default:
			return "#868E96";
	}
}

export function getRowColor(type: any) {
	switch (type) {
		case "log":
			return "";
		case "warn":
			return "#f39c121a";
		case "exception":
		case "error":
			return "#E74C3C1A";
		case "debug":
			return "";
		case "info":
			return "";
		default:
			return "";
	}
}

export const issueApi = createApi({
	reducerPath: "issue",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getIssue: builder.query<any, any>({
			query: (data: any) => ({
				url: `${data?.isBugDetails ? `/issues/${data.id}` : "/issues/public/issue"}`,
				headers: {
					"Content-Type": "application/json",
				},
				params: {
					...data,
				},
			}),
		}),
		getFlowIssue: builder.query<any, any>({
			query: (data: any) => ({
				url: `/flowIssue/get`,
				headers: {
					"Content-Type": "application/json",
				},
				params: {
					...data,
				},
			}),
		}),
		getScreenshots: builder.query<any, any>({
			query: (data: any) => ({
				url: `/screenshot/get`,
				headers: {
					"Content-Type": "application/json",
				},
				params: {
					...data,
				},
			}),
		}),
		getRecordings: builder.query<any, any>({
			query: (data: any) => ({
				url: `/recording/get`,
				headers: {
					"Content-Type": "application/json",
				},
				params: {
					...data,
				},
			}),
		}),

		getPublicIssue: builder.query<any, any>({
			query: (data: any) => ({
				url: "/issues/public/issue",
				headers: {
					"Content-Type": "application/json",
				},
				params: {
					...data,
				},
			}),
		}),

		verifyAuthIssue: builder.query<any, any>({
			query: (data: any) => ({
				url: "/issues/verify/auth",
				headers: {
					"Content-Type": "application/json",
				},
				params: {
					...data,
				},
			}),
		}),

		updateIssue: builder.mutation<any, any>({
			query: (data: any) => ({
				url: `/issues/update/${data?._id}`,
				method: "PATCH",
				body: data,
			}),
		}),
		updateDarkMode: builder.mutation<any, any>({
			query: (data: any) => ({
				url: "/user/update/darkmode",
				method: "POST",
				body: data,
			}),
		}),
		getDarkmode: builder.query<any, any>({
			query: () => ({
				url: "/user/update/darkmode",
				method: "GET",
			}),
		}),
	}),
});

export const {
	useGetIssueQuery,
	useUpdateIssueMutation,
	useGetPublicIssueQuery,
	useVerifyAuthIssueQuery,
	useUpdateDarkModeMutation,
	useGetDarkmodeQuery,
	useGetFlowIssueQuery,
	useGetRecordingsQuery,
	useGetScreenshotsQuery,
} = issueApi;
