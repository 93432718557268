import { uploadFileToAWS } from "../../shared/services/bugDetails";
import React, { useState } from "react";

export const ApiJsonToTestcase = () => {
	const [file, setFile] = useState<File | null>(null);
	const [testCases, setTestCases] = useState<string[]>([]);
	const [loading, setLoading] = useState(false);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) setFile(e.target.files[0]);
	};

	const handleUpload = async () => {
		if (!file) return;
		setLoading(true);

		console.log({ file });

		// Read the file content as a Blob using FileReader
		const fileWithData = await new Promise<any>((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				resolve({ ...file, data: reader.result, name: file.name }); // Add `data` property to the file object
			};
			reader.onerror = reject;
			reader.readAsArrayBuffer(file); // Read as ArrayBuffer
		});

		const resoponse: any = await uploadFileToAWS(fileWithData);
		const path = resoponse?.path;

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/apitestcase/json`,
				{
					method: "POST",
					body: JSON.stringify({ fileUrl: path }),
				},
			);
			const data = await response.json();
			setTestCases(data.testCases);
		} catch (error) {
			console.error("Error uploading file:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				padding: "20px",
			}}
		>
			<div
				style={{
					marginBottom: "20px",
					display: "flex",
					alignItems: "center",
					gap: "10px",
				}}
			>
				<input
					type="file"
					accept="application/json"
					onChange={handleFileChange}
					style={{ display: "none" }}
					id="file-upload"
				/>
				<label
					htmlFor="file-upload"
					style={{
						padding: "10px 20px",
						backgroundColor: "#007bff",
						color: "white",
						borderRadius: "5px",
						cursor: "pointer",
					}}
				>
					Choose File
				</label>
				<button
					onClick={handleUpload}
					disabled={!file || loading}
					style={{
						padding: "10px 20px",
						fontSize: "16px",
						color: "white",
						backgroundColor: loading || !file ? "#cccccc" : "#007bff",
						border: "none",
						borderRadius: "5px",
						cursor: loading || !file ? "not-allowed" : "pointer",
					}}
				>
					{loading ? "Processing..." : "Upload and Generate Test Cases"}
				</button>
			</div>

			{testCases.length > 0 && (
				<div style={{ width: "80%", marginTop: "20px" }}>
					<h3>Generated Test Cases:</h3>
					{testCases.map((testCase, index) => (
						<div
							key={index}
							style={{
								border: "1px solid #dddddd",
								borderRadius: "5px",
								padding: "15px",
								marginBottom: "10px",
								backgroundColor: "#f9f9f9",
							}}
						>
							{testCase.split("\n").map((line, lineIndex) => (
								<p key={lineIndex} style={{ margin: "5px 0", color: "#555" }}>
									{line}
								</p>
							))}
						</div>
					))}
				</div>
			)}
		</div>
	);
};
