import React, { useState } from "react";

// Replace with your Chrome extension ID
const EXTENSION_ID = "hijlialkdndcdnmbiaaalgkmlphhgboe";

const TrackButton = () => {
	const [url, setUrl] = useState("");

	const handleUrlChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setUrl(e.target.value);
	};

	const handleNavigateClick = () => {
		if (url) {
			// Send a message to the background script to open a new tab
			chrome.runtime.sendMessage(
				EXTENSION_ID,
				{
					msg: "SHOW-CONTROLLER",
					url
				},
				(response) => {
					if (
						chrome
							.runtime
							.lastError
					) {
						console.error(
							"Error sending message:",
							chrome
								.runtime
								.lastError
								.message
						);
						alert(
							"Failed to send message to extension."
						);
					} else {
						console.log(
							"Background response:",
							response
						);
					}
				}
			);
		} else {
			alert("Please enter a valid URL.");
		}
	};

	return (
		<div>
			<input
				type="text"
				placeholder="Enter URL"
				value={url}
				onChange={handleUrlChange}
				style={{ marginRight: "10px" }}
			/>
			<button onClick={handleNavigateClick}>
				Navigate
			</button>
		</div>
	);
};

export default TrackButton;
